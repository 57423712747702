const ja = {
  ID_TITLE: 'エンタージョブエデュ',
  LOGIN_TITLE: 'エンタージョブエデュにログイン',
  LOGIN_KAKAO: 'Kakaoアカウントでログイン',
  LOGIN_NAVER: 'Naverアカウントでログイン',
  LOGIN_GOOGLE: 'GoogleEアカウントでログイン',
  LOGIN_FACEBOOK: 'Facebookアカウントでログイン',
  LOGIN_EMAIL: 'アカウントログイン',
  LOGIN: 'ログイン',
  SIGNUP_TITLE: 'エンタージョブエデュ会員登録',
  SIGNUP_MESSAGE: '今すぐ使える2000ポイントをプレゼント！',
  SIGNUP_KAKAO: 'Kakaoアカウントでログイン',
  SIGNUP_GOOGLE: 'Naverアカウントでログイン',
  SIGNUP_NAVER: 'GoogleEアカウントでログイン',
  SIGNUP_FACEBOOK: 'Facebookアカウントでログイン',
  SIGNUP_TERMS: '進むとエンタージョブエデュの{tos}と{privacy}に同意したものとみなします。',
  SIGNUP_FORM_TITLE: '会員登録',
  SIGNUP_FORM_MESSAGE: '登録完了後に2000ポイントクーポンをプレゼント！',
  SIGNUP_FORM_SUB_MESSAGE: '(営業日ベース24時間以内)',
  SIGNUP_FORM_PROFILE_IMAGE: 'プロフィール写真',
  SIGNUP_FORM_NICKNAME: 'ニックネーム(必須)',
  SIGNUP_FORM_NICKNAME_PLACEHOLDER: '10文字以内(韓国語/英語)',
  SIGNUP_FORM_CHECK_OVERLAP: '重複チェック',
  SIGNUP_FORM_EMAIL: 'メールアドレス(必須)',
  SIGNUP_FORM_EMAIL_PLACEHOLDER: '例) enterjobedu@k-lab.me',
  SIGNUP_FORM_PHONE_NUMBER: '携帯電話番号(必須)',
  SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER: 'ハイフン不要',
  SIGNUP_FORM_SEND_VERIFICATION_CODE: '認証番号を送信する',
  SIGNUP_FORM_AUTHENTICATE: '認証する',
  SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER: '認証番号をご入力ください',
  SIGNUP_FORM_TERMS: 'お知らせ通知受信の同意(必須)',
  SIGNUP_FORM_TERMS_MESSAGE: 'エンタージョブエデュからのお知らせをお送りします。',
  SIGNUP_FORM_TERMS_AGREEMENT: '同意',
  SIGNUP_FORM_TERMS_DISAGREE: '同意しない',
  SIGNUP_FORM_COMPLETED: '登録完了',
  HEADER_USER_NAV_LOGIN: 'ログイン',
  HEADER_USER_NAV_LOGOUT: 'ログアウト',
  HEADER_USER_NAV_SIGNUP: '会員登録',
  HEADER_USER_NAV_MY_PAGE: 'マイページ',
  HEADER_USER_NAV_INQUIRY: '1:1問い合わせ',
  HEADER_USER_NAV_NOTICE: 'お知らせ',
  HEADER_USER_NAV_EVENT: 'イベント',
  HEADER_USER_NAV_BLOG: 'ブログ',
  HEADER_INSTRUCTOR_INTRODUCTION: '講師紹介',
  HEADER_GLOBAL_NAV_ONLINE: 'Online Class',
  HEADER_GLOBAL_NAV_OFFLINE: 'Offline Class',
  HEADER_GLOBAL_NAV_FOREIGNER: 'Foreigner Only',
  HEADER_GLOBAL_NAV_GOV: '🎖 정부지원교육',
  FOOTER_NAV_ABOUT_US: '会社紹介',
  FOOTER_NAV_INSTRUCTOR_RECRUITMENT: '講師募集',
  FOOTER_NAV_FAQ: 'FAQ',
  FOOTER_NAV_PRIVACY_STATEMENT: '個人情報の取り扱い',
  FOOTER_NAV_TERMS: '利用約款',
  FOOTER_COMPANY_REGISTRATION_NUMBER: '事業者登録番号 138-85-33405',
  FOOTER_BUSINESS_REPORT_NUMBER: '通信販売業届出番号 2021-서울광진-2052',
  FOOTER_LIFELONG_EDUCATION_FACILITY: '生涯学習施設 京畿道高陽市一山東区チュンアンロ1193、626号(遠隔609)',
  FOOTER_LOGO: 'コネクサスラボ',
  FOOTER_COMPANY: '(株)コネクサスラボ',
  FOOTER_DELEGATE: '代表 イ・サンファン',
  FOOTER_INFORMATION_MANAGER: '情報管理責任者 イ・サンファン',
  FOOTER_ADDRESS: '住所 ソウル特別市広津区チャヤンロ129、9階',
  FOOTER_EMAIL: 'Contact enterjobedu@k-lab.me / +8210-9866-8640',
  FOOTER_COPYRIGHT: 'COPYRIGHT © (株)コネクサスラボ ALL RIGHTS RESERVED',
  FOOTER_CHANNEL: 'エンタージョブエデュチャンネル',
  FOOTER_CHANNEL_LIST_COMMUNITY: 'COMMUNITY',
  FOOTER_CHANNEL_LIST_BLOG: 'BLOG',
  FOOTER_CHANNEL_LIST_YOUTUBE: 'YOUTUBE',
  FOOTER_CHANNEL_LIST_FACEBOOK: 'FACEBOOK',
  FOOTER_CHANNEL_LIST_INSTAGRAM: 'INSTAGRAM',
  FOOTER_SERVICE_CENTER: 'カスタマーセンター',
  FOOTER_INQUIRY: '問い合わせ',
  FOOTER_INQUIRY_TIME: '平日午前10時〜午後6時(土日祝日を除く)',
  MAIN_ONLINE_TITLE: 'BEST 今人気のオンライン講義！',
  MAIN_OFFLINE_TITLE: 'お見逃しなく！募集中のオフライン講義',
  MAIN_VIDEO_TITLE: 'ついてきて！ステップごとの就職戦略',
  MAIN_EVENT_TITLE: '会員様ONLYのイベント',
  ORDER_COMPLETED_MESSAGE: '{deadline}にお支払いを完了させてください。',
  ORDER_COMPLETED_MESSAGE_DEADLINE: '3日以内',
  MY_PAGE_LECTURES: 'マイ講義室',
  MY_PAGE_LECTURES_COLUMN_STATUS: '受講状況',
  MY_PAGE_LECTURES_COLUMN_TITLE: '講義名',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'オプション',
  MY_PAGE_LECTURES_COLUMN_GENERATION: '期',
  MY_PAGE_LECTURES_COLUMN_PERIOD: '受講期間',
  MY_PAGE_LECTURES_COLUMN_DATE: '講義日時',
  MY_PAGE_LECTURES_COLUMN_PLACE: '講義場所',
  MY_PAGE_LECTURES_COLUMN_SURVEY: 'アンケート',
  MY_PAGE_PAYMENT: 'お支払い内訳',
  MY_PAGE_PAYMENT_MESSAGE:
    '内訳には{lastMonth}のお支払い内容が表示されます。それより前のものは日付を変更して照会できます。',
  MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH: '直近6ヶ月',
  MY_PAGE_PAYMENT_SELECTED_COUPON: '割引クーポンをお選びください',
  MY_PAGE_PAYMENT_COUPON_DISABLED: 'Coupon not available for free class',
  MY_PAGE_COUPON: 'クーポン詳細',
  MY_PAGE_LIKE_LECTURES: 'ウィッシュリスト',
  MY_PAGE_EMPTY_LIKE_LECTURES: 'ウィッシュリストがありません。',
  MY_PAGE_EMPTY_LIKE_LECTURES_BTN: '講義リストを見る',
  MY_PAGE_PROFILE: '会員情報変更',
  MY_PAGE_INQUIRY: '1:1問い合わせ',
  MY_PAGE_HELLO_USER: 'ようこそ、{user}様！',
  MY_PAGE_AVAILABLE_COUPON: '使用可能なクーポンは{number}枚です',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_TITLE: 'お支払い確認書',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_MESSAGE:
    "お支払い時にご入力いただいたメールアドレス宛に'KGイニシス'より領収書を自動送信します。\r\r\nスパムメールに振り分けられる可能性がございますのでご確認ください。\r\r\nメールが届かない場合、大変お手数ですが1:1問い合わせで弊社までご連絡いただければ対応いたします。",
  MY_PAGE_PAYMENT_MODAL_REFUND_TITLE: '返金',
  MY_PAGE_PAYMENT_MODAL_REFUND_MESSAGE: '返金および返金額に関するお問い合わせは、1:1問い合わせをご利用ください。',
  MY_PAGE_PAYMENT_MODAL_INFO_TITLE: 'お支払い情報の確認',
  PLACEHOLDER_LOGIN_EMAIL: 'メールまたはIDを入力してください。',
  PLACEHOLDER_LOGIN_PASSWORD: 'パスワードをご入力ください。',
  PLACEHOLDER_COUPON_NUMBER: 'クーポン番号をご入力ください。',
  COMMON_CONFIRM: '確認',
  COMMON_LINK_TO_MAIN: 'ホーム画面に移動',
  COMMON_LINK_TO_MAIN_2: 'ホーム画面へ',
  COMMON_LINK_TO_MY_LECTURES: 'マイ講義室へ',
  COMMON_LINK_TO_INQUIRY: '1:1問い合わせへ',
  COMMON_PAYMENT: 'お支払い',
  COMMON_REFERENCE: '照会',
  COMMON_LINK_TO_SIGNUP: '会員登録',
  COMMON_READ_MORE: '詳細',
  COMMON_COUPON_ENROLLMENT: 'クーポン登録',
  COMMON_COUPON_CHECK_HISTORY: '適用できる講義を確認',
  PAYMENT_SUCCESSFULLY_COMPLETED: 'お支払いが完了しました。',
  CHECK_MY_LECTURE_ROOM: 'マイ講義室で購入した講義の情報をご確認ください。',
  NOT_A_MEMBER_YET: '会員登録をご希望ですか？',
  FORGOT_MEMBER_INFORMATION: '会員情報をお忘れですか？',
  ORDER_WILL_BE_CANCELED: '期間を過ぎるとキャンセルされます。',
  CATEGORIES_TITLE_COMPANY: '企業別',
  CATEGORIES_TITLE_FIELD: '産業別',
  CATEGORIES_TITLE_PROCESS: '就職過程別',
  CATEGORIES_COMPANY_SM: 'SM',
  CATEGORIES_COMPANY_JYP: 'JYP',
  CATEGORIES_COMPANY_YG: 'YG',
  CATEGORIES_COMPANY_CJ: 'CJ ENM',
  CATEGORIES_COMPANY_HYBE: 'HYBE',
  CATEGORIES_COMPANY_KAKAO: 'KAKAO',
  CATEGORIES_FIELD_MUSIC: '音楽',
  CATEGORIES_FIELD_MOVIE: '映画',
  CATEGORIES_FIELD_BROADCAST: '番組',
  CATEGORIES_FIELD_PERFORMANCE: '公演',
  CATEGORIES_PROCESS_INTRODUCTORY: '入門',
  CATEGORIES_PROCESS_TASK: '職務',
  CATEGORIES_PROCESS_DOCUMENT: '書類',
  CATEGORIES_PROCESS_PERSONALITY: '適性検査',
  CATEGORIES_PROCESS_INTERVIEW: '面接',
  CATEGORIES_ETC: 'その他',
  CATEGORY_COMPANY_1: 'SM',
  CATEGORY_COMPANY_2: 'JYP',
  CATEGORY_COMPANY_3: 'YG',
  CATEGORY_COMPANY_4: 'CJ ENM',
  CATEGORY_COMPANY_5: 'HYBE',
  CATEGORY_COMPANY_6: 'KAKAO',
  CATEGORY_COMPANY_7: 'その他',
  CATEGORY_FIELD_1: '音楽',
  CATEGORY_FIELD_2: '映画',
  CATEGORY_FIELD_3: '番組',
  CATEGORY_FIELD_4: '公演',
  CATEGORY_FIELD_5: 'その他',
  CATEGORY_PROCESS_1: '入門',
  CATEGORY_PROCESS_2: '職務',
  CATEGORY_PROCESS_3: '書類',
  CATEGORY_PROCESS_4: '適性検査',
  CATEGORY_PROCESS_5: '面接',
  CATEGORY_PROCESS_6: 'その他',
  NEW: '最新順',
  VIEW: '閲覧順',
  SELLING: '販売順',
  RESET: 'リセット',
  EDIT_MEMBER_INFORMATION: '会員情報変更',
  GO_YOUTUBE: 'GO YOUTUBE',
  DISCOUNT: '割引',
  ONLINE_CLASS: 'オンラインクラス',
  OFFLINE_CLASS: 'オフラインクラス',
  FOREIGNER_ONLY: '外国のお客様専用',
  DUE: 'まで',
  MORE: 'もっと見る',
  ADVANCE_RESERVATION_COUNTING: 'Deadline in {n} days',
  ADVANCE_RESERVATION_COUNTING_ONE: 'Deadline in 1 day',
  ADVANCE_RESERVATION_TODAY: "It's scheduled to close",
  POSSESSION: '保有',
  DONE_EXPIRES: '完了/満了',
  FIND_ID_PASSWORD: 'ID/PWをお忘れの方',
  NAME_OF_BANK: '銀行名',
  ACCOUNT_HOLDER: '口座名義',
  ACCOUNT_NUMBER: '口座番号',
  DEPOSIT_DEADLINE: 'お支払い期限',
  DEPOSIT_INFORMATION: 'お支払い情報',
  DEPOSIT_ACCOUNT: 'お支払い口座',
  ORDER_NUMBER: '注文番号',
  ORDER_DATE: '注文日',
  PAYMENT_DATE: 'お支払い日',
  REFUND_DATE: '返金日',
  AMOUNT: '金額',
  TOTAL_AMOUNT: '合計金額',
  USE_COUPON: 'クーポン使用',
  ORDER_COMPLETED: '注文完了',
  APPLY_FOR_A_REFUND: '返金申し込み',
  ISSUANCE_OF_PAYMENT_CONFIRMATION: 'お支払い確認書の発行',
  CONFIRM_PAYMENT_INFORMATION: 'お支払い情報の確認',
  TOTAL_AMOUNT_DUE: 'お支払い予定の合計金額',
  CONFIRM_OF_ORDER_DETAIL_AND_AGREE: '注文内容のご確認・同意',
  PAYMENT_COMPLETED: 'お支払い完了',
  REFUND_COMPLETED: '返金完了',
  PAYMENT_WAITING: 'お支払い待ち',
  REFUND_WAITING: '返金待ち',
  AMOUNT_OF_PAYMENT: 'お支払い金額',
  DISCOUNT_AMOUNT: '割引金額',
  ORIGINAL_PRICE: '定価',
  PAYMENT_LIST: 'お支払い一覧',
  PAYMENT_METHOD: 'お支払い方法',
  REFUND_POLICY: 'エンタージョブエデュの返金ポリシー',
  CONSENT_TO_PROVISION_OF_PERSONAL_INFORMATION_TO_THIRD_PARTIES: '個人情報の第三者提供に関する同意',
  AGREE_TO_THE_TERMS_OF_USE_FOR_PAYMENT_AGENCY_SERVICE: '決済代行サービス利用約款の同意',
  PURCHASE_CONFIRMATION_AGREEMENT: '購入確認同意',
  SIGNUP_FORM_ALERT_SUCCESS_NICKNAME: '使用できるハンドルネームです。',
  SIGNUP_FORM_ALERT_FAIL_NICKNAME: '既に存在するハンドルネームです。\r\r\n他のハンドルネームをご使用ください。',
  SIGNUP_FORM_ALERT_SUCCESS_SEND: '認証番号が送信されました。',
  SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION: '認証が完了しました。',
  SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION: '送信された認証番号と一致しません。',
  SIGNUP_FORM_CONFIRM_MODAL_TITLE: 'ようこそ。🎉',
  SIGNUP_FORM_CONFIRM_MODAL_MESSAGE: 'エンタージョブエデュ会員登録が\r\r\n完了しました！',
  SIGNUP_FORM_CONFIRM_MODAL_LINK_TO_MAIN: 'エンタージョブエデュに訪問する',
  SIGNUP_FORM_FILE_SIZE: '10MB以下のファイルのみ登録できます。\r\r\n\r\r\n現在のファイル容量:{size} MB',
  HEADER_USER_NAV_ADMIN: '管理者',
  MOBILE_MAIN_VIDEO_TITLE: 'ついてきてください！\r\r\nステップごとの就職戦略をご紹介します。',
  DETAIL_TABS_CLASS_INTRODUCTION: 'クラス紹介',
  DETAIL_TABS_CURRICULUM: 'カリキュラム',
  DETAIL_TABS_REVIEW: 'レビュー',
  DETAIL_SAMPLE_VIDEO_MESSAGE: '実際の講義を\r\r\n無料で体験してみてください！',
  DETAIL_CHAPTER_MESSAGE: '講義は\r\r\n以下のように構成されています！',
  DETAIL_REVIEW_MESSAGE: '合計{total}件の\r\r\nレビューがある講義です！',
  DETAIL_INSTRUCTOR: '講師',
  TUTOR_COMPANIES: 'キャリア',
  DETAIL_CLASS_TIME: '受講時間 ',
  DETAIL_CLASS_TIME_DAYS: '{day}日',
  DETAIL_NUMBER_OF_LECTURES: '講義数',
  DETAIL_TOTAL_NUMBER_OF_LECTURES: '{total}講',
  DETAIL_LECTURE_SATISFACTION: '講義満足度',
  DETAIL_LECTURE_SCHEDULE: '講義スケジュール',
  PAYMENT_IMPLEMENTS_FREE: 'Free',
  PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD: 'クレジットカード・デビットカード',
  PAYMENT_IMPLEMENTS_V_BANK: '仮想口座',
  PAYMENT_IMPLEMENTS_KAKAOPAY: 'KakaoPay',
  PAYMENT_IMPLEMENTS_TOSS: 'Toss',
  PAYMENT_IMPLEMENTS_PAYPAL: 'Paypal',
  PAYMENT_COUPON_NONE_OPTIONS: '適用できるクーポンがありません。:(',
  PAYMENT_ERROR_REQUEST: 'お支払いリクエストに失敗しました。',
  PAYMENT_ERROR: 'お支払いに失敗しました。',
  PAYMENT_ERROR_COUPON_1: '存在しないクーポンです。',
  PAYMENT_ERROR_COUPON_2: '有効期限切れのクーポンです',
  PAYMENT_ERROR_COUPON_3: 'クーポンの発行ができません。1:1問い合わせよりお問い合わせください。',
  PAYMENT_ERROR_COUPON_4: '登録済みのクーポンです。',
  ORDER_COMPLETED_VBANK:
    '仮想口座の発行が正常に処理されました。\r\r\n\r\r\n{vbank_name} {vbank_num}\r\r\nお支払い金額:{paid_amount}ウォン\r\r\n仮想口座の口座名義人:{vbank_holder}\r\r\n\r\r\n本仮想口座は{vbank_date}まで有効です。',
  ORDER_COMPLETED_PENDING: 'お支払いに支障が生じました。もう一度お試しください。',
  MY_PAGE_LECTURES_COLUMN_REMAIN_DAYS: '残日数',
  MY_PAGE_LECTURES_COLUMN_ATTENDANCE_RATE: '受講率',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'オプション',
  MY_PAGE_LECTURES_COLUMN_LECTURE_ROOM: '講義室 ',
  MY_PAGE_LECTURES_SHORTCUTS: '講義へ',
  MY_PAGE_WISH_LIST: '好きな ',
  MY_PAGE_PAYMENT_MESSAGE_MOBILE:
    '- 内訳には{lastMonth}のお支払い内容が表示されます。\r\r\n- それより前のものは日付を変更して照会できます。',
  MY_PAGE_POINT: 'ポイント詳細',
  MY_PAGE_PROFILE_MESSAGE: '会員情報変更は\r\r\n1:1問い合わせよりお願いします。',
  MY_PAGE_COUPON_MODAL_TITLE: '本クーポンは以下の講義に適用されます。',
  MY_PAGE_COUPON_MODAL_MOBILE_TITLE: '本クーポンは\r\r\n以下の講義に適用\r\r\nされます。',
  LECTURE_STATUS_ONGOING: '受講中',
  LECTURE_STATUS_PENDING: '受講予定',
  LECTURE_STATUS_END: '受講終了',
  STANDARD: '期',
  PACKAGE: '[パッケージ]',
  DAY: '日',
  MY_PAGE_NONE_LECTURE: '自分に必要な講義を今すぐ確認してみてください！',
  MY_PAGE_NONE_PAYMENT: 'お支払い内訳がありません。お問い合わせは1:1問い合わせよりお願いします。',
  MY_PAGE_NONE_COUPON: 'ご使用できるクーポンがありません。',
  MY_PAGE_NOT_EXISTENCE_COUPON: '存在しないクーポンです。クーポン番号をご確認ください！',
  LANG_KO: '韓国語',
  LANG_EN: '英語',
  LANG_JA: '日本語',
  LANG_VI: 'ベトナム語',
  PAYMENT: 'お支払い',
  GO_TO_CLASSROOM: '講義室に移動する',
  GO_TO_CLASSLIST: '강의목록 바로가기',
  CURRENCY_KOR: 'ウォン',
  MAKE_A_PAYMENT: 'お支払い',
  MAKE_A_WAITING: '待ちリストに追加 👆🏻',
  MAKE_A_WAITING_TEXT:
    '待機リストに登録しますか？ \n\n* オープン時には、アラートメッセージまたはメールでお知らせいたします。',
  WAITING_SUCCESS: 'ウェイティングリスト申し込み完了',
  WAITING_FAIL_ALREADY: 'あなたはすでに待機者名簿にいます。 \n\n* できるだけ早く連絡いたします。',
  WAITING_ALREADY_PURCHASED: 'すでに決済(または決済中)された講義です。',
  ABBREVIATION_MONDAY: '月',
  ABBREVIATION_TUESDAY: '火',
  ABBREVIATION_WEDNESDAY: '水',
  ABBREVIATION_THURSDAY: '木',
  ABBREVIATION_FRIDAY: '金',
  ABBREVIATION_SATURDAY: '土',
  ABBREVIATION_SUNDAY: '日',
  MEET_YOUR_CRITERIA: '条件に一致する講義を見る',
  PROFILE_IMAGE_ALT: 'プロフィール写真',
  FOLD: '閉じる',
  PAYMENT_FAIL: 'お支払い失敗',
  CLASSROOM_ATTACHED_FILE: '講義資料',
  CLASSROOM_DOWNLOAD: 'ダウンロードする',
  CLASSROOM_CHAPTER: '講義目次',
  CLASSROOM_COMPLETE_RATE: '受講率',
  CLASSROOM_ATTENDED_TIME: '受講時間 ',
  CLASSROOM_CLASS_TIME: '講義時間',
  LOGIN_ALERT_UNAUTH: 'メールアドレスまたはパスワードをご確認ください。',
  LOGIN_FACEBOOK_ERROR: 'Facebookログインに失敗しました。',
  LOGIN_VALIDATE_ERROR: 'メールアドレスまたはパスワードをご確認ください。',
  SOCIAL_LOGIN_ERROR: 'ソーシャルログイン中に不明なエラーが発生しました。',
  SIGNUP_FROM_INTRO:
    'エンターテインメントの今と未来のために、\r\nエンタージョブエデュと一緒にチャレンジしてみませんか。',
  SIGNUP_FORM_USERNAME: '氏名（必須）',
  SIGNUP_FORM_USERNAME_PLACEHOLDER: 'ご本人のお名前をご入力ください(ハングル/アルファベット)。',
  SIGNUP_FORM_AUTHENTICATE_ERROR: '6桁の認証番号をご確認ください。',
  SIGNUP_FROM_ALERT_FILE_UPLOAD_ERROR_MESSAGE: '10MB以下のファイルのみ登録できます。',
  SIGNUP_FORM_SUCCESS_NICKNAME: '使用できるハンドルネームです。',
  SIGNUP_FORM_NICKNAME_VALIDATION_ERROR_MESSAGE: 'ハングルやアルファベットを使って最大１０文字以内でご入力ください。',
  SIGNUP_FORM_FAIL_NICKNAME: '既に使用中のハンドルネームです。',
  SIGNUP_FORM_FAIL_EMAIL: 'メール様式に合わせてご入力ください。',
  ID_SIGNUP_ERROR_1: '既に登録済みの携帯電話番号です。',
  ID_SIGNUP_ERROR_2: '認証番号の送信をもう一度お試しください。',
  HEADER_LOGGED_IN_USER_MESSAGE: '{user}様、目標に向かってチャレンジしてみませんか！',
  DETAIL_LECTURE_NOT_LOGGED_IN_USER: 'お支払いいただくにはログインが必要です。',
  GENERAL_NOT_LOGGED_IN_USER: '進めるにはログインが必要です。',
  DETAIL_LECTURE_NOT_SELECT_SCHEDULE: '講義スケジュールをお選びください。',
  DETAIL_LECTURE_NOT_SELLING: '販売できない商品です。 1:1問い合わせで弊社までご連絡いただければ対応いたします。',
  PAYMENT_ERROR_2: 'お支払いに失敗しました。 エラー内容:',
  PAYMENT_ALREADY_BEEN_PAID: '既にお支払いいただいた講義です。',
  PAYMENT_NOT_PAID_MESSAGE:
    '0ウォンでお支払いいただけません。1:1問い合わせで弊社までご連絡いただければ対応いたします。',
  ID_COUPON_ERROR_MEESGAE_1: '存在しないクーポンです。',
  ID_COUPON_ERROR_MEESGAE_2: '有効期限切れのクーポンです',
  ID_COUPON_ERROR_MEESGAE_3: 'クーポンの発行ができません。 1:1問い合わせをご利用ください。',
  ID_COUPON_ERROR_MEESGAE_4: '既に登録済みのクーポンです。',
  MY_PAGE_PROFILE_MESSAGE_1: '会員の貴重な情報の修正はエンタージョブエデュの運営スタッフのみ行うことができます！',
  MY_PAGE_PROFILE_MESSAGE_2: '1:1問い合わせで弊社までご連絡いただければ迅速に対応いたします。',
  MY_PAGE_PROFILE_INQUIRY: '1:1問い合わせを作成する',
  MY_PAGE_PROFILE_SIGNOUT: '会員を退会する',
  MY_PAGE_SIGNOUT_MESSAGE: '「本当に退会しますか？ とても残念です」',
  MY_PAGE_SIGNOUT_TERM_1: '一度退会すると、受講予定の講義や現在受講中の講義をお受けできなくなります。',
  MY_PAGE_SIGNOUT_TERM_2:
    '一度退会すると、お持ちのクーポンはすべて削除されます。再登録しても削除された情報は復旧できませんのでご了承ください。',
  MY_PAGE_SIGNOUT_TERM_3: '過去に作成したレビューなどは削除されません。',
  MY_PAGE_SIGNOUT_TERM_AGREE: '上記の会員退会時の注意事項を確認し、これに同意します。',
  MY_PAGE_SIGNOUT_FORM: 'エンタージョブエデュを退会する理由は…',
  MY_PAGE_SIGNOUT_CONFIRM: '退会を完了する',
  MY_PAGE_SIGNOUT_POLICY: '退会したアカウントは30日間加入できません！',
  MY_PAGE_NONE_EXPIRED_COUPON: '使用済み/有効期限切れのクーポンがありません。',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD1: '迷惑メールも確認する',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD2: '1:1問い合わせより',
  MY_PAGE_PAYMENT_MODAL_REFUND_BOLD: '1:1問い合わせより',
  COMMON_CANCEL: 'キャンセル',
  DEVELOP_MESSAGE: 'エンタージョブエデュサービスを準備しています。',
  USED: '使用済み',
  EXPIRED: '有効期限切れ',
  GO_TO_SERVICE_CENTER: 'カスタマーセンターはこちら',
  ERROR_GENERAL: '不明なエラー発生',
  ERROR_NETWORK: 'ネットワークの接続状態をご確認ください。',
  ID_SIGNUP_ERROR_3: '既に登録済みのメールアドレスです。',
  ID_SIGNUP_ERROR_4: '返信メールの送信に失敗しました。',
  ID_SIGNUP_ERROR_5: '既に登録済みのユーザーです。',
  ID_SIGNUP_ERROR_6: '退会後30日経過しないと再登録できません。',
  SIGNUP_FORM_INTERESTED_COMPANY: '興味のある会社（必須）',
  SIGNUP_FORM_INTERESTED_COMPANY_SELECT: '企業を選択してください。 (複数選択)',
  SIGNUP_FORM_INTERESTED_COMPANY_ETC: 'Other',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER: 'その他会社（必須）',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER_PLACEHOLDER: '他の会社を入力してください。',
  SIGNUP_FORM_COUNTRY: '国情報（必須）',
  MY_PAGE_LECTURES_SINGLE: '単一講義',
  MY_PAGE_LECTURES_PACKAGE: 'パッケージ講義',
  마감임박: 'まもなく締め切り',
  '이미 마감된 강의입니다': 'この講座は締め切りました',

  'Album Credit DB': 'Album Credit DB',
  '국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요.':
    '"韓国で発売された{totalCount}枚のアルバムクレジット情報集め！{br}k-popを世界中に広めているスタッフの情報がご確認できます。"',
  RELEASE_DATE_DESC: '最新順',
  RELEASE_DATE_ASC: '発売順',
  '전체 {totalCount}개': '合計{totalCount}枚',
  기획사: '事務所',
  아티스트: 'アーティスト',
  '{totalCount}개의 앨범 크레딧 보기': 'アルバム {totalCount}枚のクレジットを見る',
  발매일: '発売日',
  '아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?': 'このアーティストの他のアルバム情報も気になりませんか？',
  '앨범 크레딧 "이분들의 땀과 노력이 담겼어요!"': 'アルバムクレジット{br}「この方々の汗と努力の結晶」',
  '다른 앨범 정보도 궁금하지 않으세요?': '他のアルバム情報も気になりませんか？',
  MY_PAGE_LECTURES_COLUMN_REVIEW: 'Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE: 'Course Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER:
    'When writing a course review, we will give you a 1,000 won discount coupon within 1 business day!',
  MY_PAGE_LECTURES_WRITE_REVIEW_COMPLETE: 'Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW: 'Write',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE: 'Total Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER:
    'Write your overall opinion on the lecture! (at least 100bytes )\r\nYour review will be a huge asset to those who are considering purchasing our courses and  membership.  ',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER:
    'The question below is to improve EnterJob Edu content. {br}Only Enter Job Edu staff members will be able check this  answer, so please feel free to answer!',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION:
    '<strong>Class Satisfaction</strong> : Were you satisfied with the composition of content, accuracy of information, and overall topic suitablity?',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION:
    "<strong>Class Time Satisfaction</strong> : Were you satisfied with the opening time of the class, the instructor's compliance with the class time, and the overall running time of the lecture?",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION:
    "<strong>Instructor satisfaction</strong> : Please evaluate the instructor's knowledge, experience, and delivery skills!",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER:
    'If there was anything that you were specifically dissatisfied with, we will try our best to improve. ( at least 100bytes )',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION:
    'Is there any other type content or needed functions that Enter Job Edu should consider?',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER:
    'Please write down the type of information you struggled to find while preparing for employment at an entertainment company or what you want to hear from the incumbent!\r\nHere at Enter Job Edu, we will always try our best to solve your problems.',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT: 'Review Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR: 'Please fill in all the categories that are mandatory.',
  REVIEW_WARNING_RATE: 'You must take 30% or higher to write.',
  REVIEW_WARNING_ENDDATE: 'You can write after the end of the course.',
  COMMON_CLOSE: '閉じる',
  COMMON_ALWAYS_CLOSE: 'もう開けない',
  COMMON_1DAY_CLOSE: '今日開かない',
  SEARCH_INPUT_PLACEHOLDER: '関心のある職務、企業などを検索してください。',
  SEARCH_RECENT_KEYWORDS_TITLE: '最近のクエリ',
  COMMON_DELETE_ALL: 'すべて削除',
  SEARCH_RECENT_KEYWORD_EMPTY: '最近のクエリはありません。',
  SEARCH_RESULT_HEADER: '{keyword}の検索結果',
  SEARCH_RESULT_EMPTY_KEYWORD: '{keyword}の検索結果はありません。',
  SEARCH_RESULT_EMPTY_DESC:
    '書くことを確認してください。<br />単語のスペルが正しいことを確認してください。<br />ハングルを英語で、または英語をハングルで入力したことを確認してください。<br />別の検索語を使用してみてください。',
  SEARCH_RECOMMEND_KEYWORDS_TITLE: 'おすすめのクエリ',
  SEARCH_RECOMMEND_CONTENTS_LECTURES: '今人気のレッスンもお見逃しなく！',
  SEARCH_RECOMMEND_CONTENTS_ALBUMS: 'おすすめ！ 最近リリースされたアルバムのクレジットを確認してください。',
  MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE: '講義資料',
  MY_PAGE_LECTURES_DOWNLOAD_FILE: 'Download',
  MY_PAGE_PROFILE_UPDATE_SUCCESS: '情報を修正しました。',
  NO_LECTURES: 'コンテンツ準備中です。',
  LIST: 'リスト',
  NOTICE_DETAIL_ATTACH: '添付ファイル',
  LECTURE_SELLING_COUNT: '{count}名受講',
  REQUEST_FOR_CLASS: '신청하기 👆🏻',
};

export default ja;
