const en = {
  ID_TITLE: 'Enterjob Edu',
  LOGIN_TITLE: 'Enterjob Edu Login',
  LOGIN_KAKAO: 'Login with Kakao',
  LOGIN_NAVER: 'Login with Naver',
  LOGIN_GOOGLE: 'Login with Google',
  LOGIN_FACEBOOK: 'Login with Facebook',
  LOGIN_EMAIL: 'Login with Account',
  LOGIN: 'Login',
  SIGNUP_TITLE: 'Sign up for Enterjob Edu',
  SIGNUP_MESSAGE: 'We will give you 2000 points that you can use straight away! ',
  SIGNUP_KAKAO: 'Sign up with Kakao',
  SIGNUP_GOOGLE: 'Sign up with Google',
  SIGNUP_NAVER: 'Sign up with Naver',
  SIGNUP_FACEBOOK: 'Sign up with Facebook',
  SIGNUP_TERMS: "By continuing, you agree to Enterjob Edu's {tos} and {privacy}.",
  SIGNUP_FORM_TITLE: 'Signup',
  SIGNUP_FORM_MESSAGE: "Once you've signed up, we'll give you a 2000 discount coupon!",
  SIGNUP_FORM_SUB_MESSAGE: '(Within 24 hours on business days)',
  SIGNUP_FORM_PROFILE_IMAGE: 'Profile Picture',
  SIGNUP_FORM_NICKNAME: 'Nickname (required)',
  SIGNUP_FORM_NICKNAME_PLACEHOLDER: 'Please enter no more than 10 characters. (Korean/English)',
  SIGNUP_FORM_CHECK_OVERLAP: 'Check availability',
  SIGNUP_FORM_EMAIL: 'Email (required)',
  SIGNUP_FORM_EMAIL_PLACEHOLDER: 'e.g. enterjobedu@k-lab.me ',
  SIGNUP_FORM_PHONE_NUMBER: 'Mobile phone number (required)',
  SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER: "Please enter without '-'.",
  SIGNUP_FORM_SEND_VERIFICATION_CODE: 'Verification code sent',
  SIGNUP_FORM_AUTHENTICATE: 'Verify',
  SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER: 'Please enter your verification code. ',
  SIGNUP_FORM_TERMS: 'Consent to receiving marketing correspondence (required)',
  SIGNUP_FORM_TERMS_MESSAGE: 'You can receive a variety of news from Enterjob Edu.',
  SIGNUP_FORM_TERMS_AGREEMENT: 'Agree',
  SIGNUP_FORM_TERMS_DISAGREE: 'Disagree',
  SIGNUP_FORM_COMPLETED: 'Signup Complete',
  HEADER_USER_NAV_LOGIN: 'Login ',
  HEADER_USER_NAV_LOGOUT: 'Logout',
  HEADER_USER_NAV_SIGNUP: 'Signup',
  HEADER_USER_NAV_MY_PAGE: 'My Page',
  HEADER_USER_NAV_INQUIRY: '1:1 Inquiry',
  HEADER_USER_NAV_NOTICE: 'Notice',
  HEADER_USER_NAV_EVENT: 'Event',
  HEADER_USER_NAV_BLOG: 'Blog',
  HEADER_INSTRUCTOR_INTRODUCTION: 'About the Instructors ',
  HEADER_GLOBAL_NAV_ONLINE: 'Online Class',
  HEADER_GLOBAL_NAV_OFFLINE: 'Offline Class',
  HEADER_GLOBAL_NAV_FOREIGNER: 'Foreigner Only',
  HEADER_GLOBAL_NAV_GOV: '🎖 정부지원교육',
  FOOTER_NAV_ABOUT_US: 'About Us',
  FOOTER_NAV_INSTRUCTOR_RECRUITMENT: 'Instructor Recruitment',
  FOOTER_NAV_FAQ: 'FAQ',
  FOOTER_NAV_PRIVACY_STATEMENT: 'Privacy Policy',
  FOOTER_NAV_TERMS: 'Terms of Use',
  FOOTER_COMPANY_REGISTRATION_NUMBER: 'Company Registration Number: 138-85-33405',
  FOOTER_BUSINESS_REPORT_NUMBER: 'Mail-order business report: 2021-서울광진-2052',
  FOOTER_LIFELONG_EDUCATION_FACILITY:
    'Lifelong Education Facility, Unit # 626, 1193 Jungang-ro, Ilsandong-gu, Goyang-si, Gyeonggi-do',
  FOOTER_LOGO: 'Klab Company',
  FOOTER_COMPANY: 'Klab Company ',
  FOOTER_DELEGATE: 'Co-CEO Hyungkyu Kim, Sanghwan Lee',
  FOOTER_INFORMATION_MANAGER: 'Information Manager Sanghwan Lee',
  FOOTER_ADDRESS: 'Address 9F, 129 Jayang-ro, Gwangjin-gu, Seoul',
  FOOTER_EMAIL: 'Contact enterjobedu@k-lab.me / +8210-9866-8640',
  FOOTER_COPYRIGHT: 'COPYRIGHT © Klab Company ALL RIGHTS RESERVED',
  FOOTER_CHANNEL: 'Enterjob Edu Channel ',
  FOOTER_CHANNEL_LIST_COMMUNITY: 'COMMUNITY',
  FOOTER_CHANNEL_LIST_BLOG: 'BLOG',
  FOOTER_CHANNEL_LIST_YOUTUBE: 'YOUTUBE',
  FOOTER_CHANNEL_LIST_FACEBOOK: 'FACEBOOK',
  FOOTER_CHANNEL_LIST_INSTAGRAM: 'INSTAGRAM',
  FOOTER_SERVICE_CENTER: 'Customer Service Center',
  FOOTER_INQUIRY: 'Inquiries',
  FOOTER_INQUIRY_TIME: 'Weekdays 10am to 6pm (excl. weekends and public holidays)',
  MAIN_ONLINE_TITLE: 'BEST Most popular online class! ',
  MAIN_OFFLINE_TITLE: "Don't miss out! Currently accepting applicants for offline lecture",
  MAIN_VIDEO_TITLE: 'A step-by-step guide to successful employment.',
  MAIN_EVENT_TITLE: 'Event for Members ONLY ',
  ORDER_COMPLETED_MESSAGE: 'Please complete payment by {deadline}',
  ORDER_COMPLETED_MESSAGE_DEADLINE: 'Within 3 days',
  MY_PAGE_LECTURES: 'My Lectures',
  MY_PAGE_LECTURES_COLUMN_STATUS: 'Attendance Status',
  MY_PAGE_LECTURES_COLUMN_TITLE: 'Lecture Title ',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'Option ',
  MY_PAGE_LECTURES_COLUMN_GENERATION: 'Class Number ',
  MY_PAGE_LECTURES_COLUMN_PERIOD: 'Course Period ',
  MY_PAGE_LECTURES_COLUMN_DATE: 'Lecture Date and Time ',
  MY_PAGE_LECTURES_COLUMN_PLACE: 'Lecture Location ',
  MY_PAGE_LECTURES_COLUMN_SURVEY: 'Survey ',
  MY_PAGE_PAYMENT: 'Payment History',
  MY_PAGE_PAYMENT_MESSAGE:
    'Your payment history for {lastMonth} will be automatically shown. Please change the date to see previous transactions. ',
  MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH: 'Past 6 months ',
  MY_PAGE_PAYMENT_SELECTED_COUPON: 'Please select a discount coupon. ',
  MY_PAGE_PAYMENT_COUPON_DISABLED: 'Coupon not available for free class',
  MY_PAGE_COUPON: 'Coupon Details ',
  MY_PAGE_LIKE_LECTURES: 'Wishlist',
  MY_PAGE_EMPTY_LIKE_LECTURES: 'Empty Wishlist',
  MY_PAGE_EMPTY_LIKE_LECTURES_BTN: 'View lectures',
  MY_PAGE_PROFILE: 'Edit Profile ',
  MY_PAGE_INQUIRY: '1:1 Inquiry',
  MY_PAGE_HELLO_USER: 'Welcome {user}!',
  MY_PAGE_AVAILABLE_COUPON: 'You have {number} redeemable coupons.  ',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_TITLE: 'Payment Confirmation ',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_MESSAGE:
    "A payment confirmation receipt will be automatically sent to the entered email address through 'KG INICIS'. Please check your Junk folder as well.\r\r\n\r\r\nIf you do not receive a payment confirmation receipt, please contact us through our 1:1 Inquiry service. ",
  MY_PAGE_PAYMENT_MODAL_REFUND_TITLE: 'Request Refund ',
  MY_PAGE_PAYMENT_MODAL_REFUND_MESSAGE:
    'To request or inquire about a refund, please contact us through our 1:1 Inquiry service for details.',
  MY_PAGE_PAYMENT_MODAL_INFO_TITLE: 'Confirm Payment Information ',
  PLACEHOLDER_LOGIN_EMAIL: 'Please enter your email address or ID.',
  PLACEHOLDER_LOGIN_PASSWORD: 'Please enter password.',
  PLACEHOLDER_COUPON_NUMBER: 'Please enter coupon code.',
  COMMON_CONFIRM: 'Confirm',
  COMMON_LINK_TO_MAIN: 'Go to Main Page',
  COMMON_LINK_TO_MAIN_2: 'Go to Main Page',
  COMMON_LINK_TO_MY_LECTURES: 'Go to My Lectures',
  COMMON_LINK_TO_INQUIRY: 'Go to 1:1 Inquiry ',
  COMMON_PAYMENT: 'Pay ',
  COMMON_REFERENCE: 'Inquire',
  COMMON_LINK_TO_SIGNUP: 'Sign up',
  COMMON_READ_MORE: 'Read More',
  COMMON_COUPON_ENROLLMENT: 'Register Coupon',
  COMMON_COUPON_CHECK_HISTORY: 'Check coupon applicability ',
  PAYMENT_SUCCESSFULLY_COMPLETED: 'Your payment has been successfully completed.',
  CHECK_MY_LECTURE_ROOM: 'Check My Lectures ',
  NOT_A_MEMBER_YET: 'Still not a member? ',
  FORGOT_MEMBER_INFORMATION: 'Forgotten your membership details?',
  ORDER_WILL_BE_CANCELED: 'After a period of time, your order will be cancelled.',
  CATEGORIES_TITLE_COMPANY: 'By company ',
  CATEGORIES_TITLE_FIELD: 'By industry ',
  CATEGORIES_TITLE_PROCESS: 'By hiring process',
  CATEGORIES_COMPANY_SM: 'SM',
  CATEGORIES_COMPANY_JYP: 'JYP',
  CATEGORIES_COMPANY_YG: 'YG',
  CATEGORIES_COMPANY_CJ: 'CJ ENM',
  CATEGORIES_COMPANY_HYBE: 'HYBE',
  CATEGORIES_COMPANY_KAKAO: 'KAKAO',
  CATEGORIES_FIELD_MUSIC: 'Music ',
  CATEGORIES_FIELD_MOVIE: 'Film ',
  CATEGORIES_FIELD_BROADCAST: 'Broadcasting ',
  CATEGORIES_FIELD_PERFORMANCE: 'Performance ',
  CATEGORIES_PROCESS_INTRODUCTORY: 'Introduction',
  CATEGORIES_PROCESS_TASK: 'Task',
  CATEGORIES_PROCESS_DOCUMENT: 'Documents ',
  CATEGORIES_PROCESS_PERSONALITY: 'Personality and Aptitude',
  CATEGORIES_PROCESS_INTERVIEW: 'Interview ',
  CATEGORIES_ETC: 'Other',
  CATEGORY_COMPANY_1: 'SM',
  CATEGORY_COMPANY_2: 'JYP',
  CATEGORY_COMPANY_3: 'YG',
  CATEGORY_COMPANY_4: 'CJ ENM',
  CATEGORY_COMPANY_5: 'HYBE',
  CATEGORY_COMPANY_6: 'KAKAO',
  CATEGORY_COMPANY_7: 'Other',
  CATEGORY_FIELD_1: 'Music ',
  CATEGORY_FIELD_2: 'Film ',
  CATEGORY_FIELD_3: 'Broadcasting ',
  CATEGORY_FIELD_4: 'Performance ',
  CATEGORY_FIELD_5: 'Other',
  CATEGORY_PROCESS_1: 'Introduction',
  CATEGORY_PROCESS_2: 'Task',
  CATEGORY_PROCESS_3: 'Documents ',
  CATEGORY_PROCESS_4: 'Personality and Aptitude',
  CATEGORY_PROCESS_5: 'Interview ',
  CATEGORY_PROCESS_6: 'Other',
  NEW: 'Order by Most Recent ',
  VIEW: 'Order by Views',
  SELLING: 'Order by Best Selling',
  RESET: 'Reset',
  EDIT_MEMBER_INFORMATION: 'Edit Member Information ',
  GO_YOUTUBE: 'GO YOUTUBE',
  DISCOUNT: 'Discount',
  ONLINE_CLASS: 'Online Class',
  OFFLINE_CLASS: 'Offline Class',
  FOREIGNER_ONLY: 'Foreigners ONLY',
  DUE: 'Due',
  MORE: 'See More',
  ADVANCE_RESERVATION_COUNTING: 'Deadline in {n} days',
  ADVANCE_RESERVATION_COUNTING_ONE: 'Deadline in 1 day',
  ADVANCE_RESERVATION_TODAY: "It's scheduled to close",
  POSSESSION: 'Possessions',
  DONE_EXPIRES: 'Completed/Expired',
  FIND_ID_PASSWORD: 'Find ID/Password',
  NAME_OF_BANK: 'Name of Bank',
  ACCOUNT_HOLDER: 'Account Holder',
  ACCOUNT_NUMBER: 'Account Number',
  DEPOSIT_DEADLINE: 'Deposit Deadline',
  DEPOSIT_INFORMATION: 'Deposit Information',
  DEPOSIT_ACCOUNT: 'Deposit Account ',
  ORDER_NUMBER: 'Order Number ',
  ORDER_DATE: 'Order Date',
  PAYMENT_DATE: 'Payment Date',
  REFUND_DATE: 'Refund Date',
  AMOUNT: 'Amount',
  TOTAL_AMOUNT: 'Total Amount',
  USE_COUPON: 'Redeem Coupon',
  ORDER_COMPLETED: 'Order Complete',
  APPLY_FOR_A_REFUND: 'Apply for a Refund',
  ISSUANCE_OF_PAYMENT_CONFIRMATION: 'Issue Payment Confirmation Receipt ',
  CONFIRM_PAYMENT_INFORMATION: 'Confirm Payment Information ',
  TOTAL_AMOUNT_DUE: 'Total Amount Due',
  CONFIRM_OF_ORDER_DETAIL_AND_AGREE: 'Confirm and Accept Order',
  PAYMENT_COMPLETED: 'Payment Complete',
  REFUND_COMPLETED: 'Refund Complete',
  PAYMENT_WAITING: 'Payment Pending',
  REFUND_WAITING: 'Refund Pending ',
  AMOUNT_OF_PAYMENT: 'Payment Amount ',
  DISCOUNT_AMOUNT: 'Discount Amount ',
  ORIGINAL_PRICE: 'Original Price',
  PAYMENT_LIST: 'Payment History ',
  PAYMENT_METHOD: 'Payment Method',
  REFUND_POLICY: 'Refund Policy',
  CONSENT_TO_PROVISION_OF_PERSONAL_INFORMATION_TO_THIRD_PARTIES:
    'Consent to Provision of Personal Information to Third Parties',
  AGREE_TO_THE_TERMS_OF_USE_FOR_PAYMENT_AGENCY_SERVICE: 'Agree to the use of Paying Agency Service',
  PURCHASE_CONFIRMATION_AGREEMENT: 'Consent to Purchase Agreement ',
  SIGNUP_FORM_ALERT_SUCCESS_NICKNAME: 'Valid nickname ',
  SIGNUP_FORM_ALERT_FAIL_NICKNAME: 'This nickname is already in use. Please try another one. ',
  SIGNUP_FORM_ALERT_SUCCESS_SEND: 'Verification code has been sent ',
  SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION: 'Verification has been completed.',
  SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION: 'Please check that the verification code you entered is correct.',
  SIGNUP_FORM_CONFIRM_MODAL_TITLE: 'Welcome 🎉',
  SIGNUP_FORM_CONFIRM_MODAL_MESSAGE: 'Signup for Enterjob Edu is complete! ',
  SIGNUP_FORM_CONFIRM_MODAL_LINK_TO_MAIN: 'Go to Enterjob Edu main page. ',
  SIGNUP_FORM_FILE_SIZE: '"Only files less than 10 MB can be registered. \r\r\n\r\r\nCurrent file size: {size} MB"',
  HEADER_USER_NAV_ADMIN: 'Manager ',
  MOBILE_MAIN_VIDEO_TITLE: 'Just do this! Check out step-by-step strategies for employment!',
  DETAIL_TABS_CLASS_INTRODUCTION: 'Class Introduction ',
  DETAIL_TABS_CURRICULUM: 'Curriculum ',
  DETAIL_TABS_REVIEW: 'Review ',
  DETAIL_SAMPLE_VIDEO_MESSAGE: 'Experience actual lecture content for free! ',
  DETAIL_CHAPTER_MESSAGE: 'The lecture is comprised of the following chapters! ',
  DETAIL_REVIEW_MESSAGE: 'This lecture has a total of {total} reviews! ',
  DETAIL_INSTRUCTOR: 'Instructor ',
  TUTOR_COMPANIES: 'Career ',
  DETAIL_CLASS_TIME: 'Class Time ',
  DETAIL_CLASS_TIME_DAYS: '{day} days ',
  DETAIL_NUMBER_OF_LECTURES: 'Number of Lectures ',
  DETAIL_TOTAL_NUMBER_OF_LECTURES: 'Total of {total} Lectures ',
  DETAIL_LECTURE_SATISFACTION: 'Lecture Satisfaction ',
  DETAIL_LECTURE_SCHEDULE: 'Lecture Schedule ',
  PAYMENT_IMPLEMENTS_FREE: 'Free',
  PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD: 'Credit (Debit) Card ',
  PAYMENT_IMPLEMENTS_V_BANK: 'Virtual Account ',
  PAYMENT_IMPLEMENTS_KAKAOPAY: 'KAKAO PAY',
  PAYMENT_IMPLEMENTS_TOSS: 'TOSS',
  PAYMENT_IMPLEMENTS_PAYPAL: 'Paypal',
  PAYMENT_COUPON_NONE_OPTIONS: 'No applicable coupons :(',
  PAYMENT_ERROR_REQUEST: 'Payment request has failed.',
  PAYMENT_ERROR: 'Payment has failed.',
  PAYMENT_ERROR_COUPON_1: 'This coupon does not exist.',
  PAYMENT_ERROR_COUPON_2: 'This coupon has already expired.',
  PAYMENT_ERROR_COUPON_3: 'Coupon cannot be issued. Please use our 1:1 inquiry service. ',
  PAYMENT_ERROR_COUPON_4: 'This coupon has already been registered.',
  ORDER_COMPLETED_VBANK:
    'Your virtual account has been successfully issued. \r\n\r\n{vbank_name} {vbank_num}\r\nDeposit amount: {paid_amount} KRW\r\nVirtual account holder: {vbank_holder}\r\n\r\nThis virtual account is valid until {vbank_date}."',
  ORDER_COMPLETED_PENDING: 'There was a problem with your payment. Please try again. ',
  MY_PAGE_LECTURES_COLUMN_REMAIN_DAYS: 'Remaining days ',
  MY_PAGE_LECTURES_COLUMN_ATTENDANCE_RATE: 'Attendance Rate ',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'Option ',
  MY_PAGE_LECTURES_COLUMN_LECTURE_ROOM: 'Lecture Room',
  MY_PAGE_LECTURES_SHORTCUTS: 'View Lecture',
  MY_PAGE_WISH_LIST: 'Like ',
  MY_PAGE_PAYMENT_MESSAGE_MOBILE:
    '- Payment details of {lastMonth} are shown by default.\r\r\n- Previous details can be viewed by changing the date.',
  MY_PAGE_POINT: 'Point Details ',
  MY_PAGE_PROFILE_MESSAGE: 'Use the 1:1 inquiry service to edit your profile. ',
  MY_PAGE_COUPON_MODAL_TITLE: 'The coupon will be applied to the following lecture. ',
  MY_PAGE_COUPON_MODAL_MOBILE_TITLE: 'The coupon will applied to the following lecture. ',
  LECTURE_STATUS_ONGOING: 'Lecture in progress ',
  LECTURE_STATUS_PENDING: 'Lecture to be taken ',
  LECTURE_STATUS_END: 'End of Lecture ',
  STANDARD: 'Standard',
  PACKAGE: '[Package]',
  DAY: 'Day ',
  MY_PAGE_NONE_LECTURE: 'Check out the lectures you need now! ',
  MY_PAGE_NONE_PAYMENT: 'No payment history. Please use our 1:1 inquiry service. ',
  MY_PAGE_NONE_COUPON: 'No available coupons. ',
  MY_PAGE_NOT_EXISTENCE_COUPON: 'Invalid coupon. Please check the coupon number! ',
  LANG_KO: 'Korean ',
  LANG_EN: 'English ',
  LANG_JA: 'Japanese ',
  LANG_VI: 'Vietnamese ',
  PAYMENT: 'Payment ',
  GO_TO_CLASSROOM: 'To to classroom ',
  GO_TO_CLASSLIST: '강의목록 바로가기',
  CURRENCY_KOR: 'KRW',
  MAKE_A_PAYMENT: 'Make a payment',
  MAKE_A_WAITING: 'Join the Waitlist 👆🏻',
  MAKE_A_WAITING_TEXT:
    'Would you like to proceed with registering as a waitlist applicant? \n\n* We will notify you via an alert message or email when we open.',
  WAITING_SUCCESS: 'Waiting list application completed.',
  WAITING_FAIL_ALREADY: 'You have already applied for waiting list. \n\n* We will contact you as soon as possible.',
  WAITING_ALREADY_PURCHASED: 'You have already paid (or being paid) for this class.',
  ABBREVIATION_MONDAY: 'Monday ',
  ABBREVIATION_TUESDAY: 'Tuesday ',
  ABBREVIATION_WEDNESDAY: 'Wednesday ',
  ABBREVIATION_THURSDAY: 'Thursday ',
  ABBREVIATION_FRIDAY: 'Friday ',
  ABBREVIATION_SATURDAY: 'Saturday ',
  ABBREVIATION_SUNDAY: 'Sunday ',
  MEET_YOUR_CRITERIA: 'See lectures that meet your criteria. ',
  PROFILE_IMAGE_ALT: 'Profile Image ',
  FOLD: 'Fold ',
  PAYMENT_FAIL: 'Payment failed ',
  CLASSROOM_ATTACHED_FILE: 'Attached Files ',
  CLASSROOM_DOWNLOAD: 'Download ',
  CLASSROOM_CHAPTER: 'Chapter List ',
  CLASSROOM_COMPLETE_RATE: 'Completion Rate ',
  CLASSROOM_ATTENDED_TIME: 'Attended Time ',
  CLASSROOM_CLASS_TIME: 'Class Time ',
  LOGIN_ALERT_UNAUTH: 'Please check your email or password. ',
  LOGIN_FACEBOOK_ERROR: 'Facebook login failed.',
  LOGIN_VALIDATE_ERROR: 'Please check your email address and password. ',
  SOCIAL_LOGIN_ERROR: 'An unknown error has occurred during social login.',
  SIGNUP_FROM_INTRO: 'For the present and future of entertainment industry,\r\n"Enterjob Edu" always joins you.',
  SIGNUP_FORM_USERNAME: 'Name (required)',
  SIGNUP_FORM_USERNAME_PLACEHOLDER: 'Please type your name. (Korean/English)',
  SIGNUP_FORM_AUTHENTICATE_ERROR: 'Please check your 6-digit verification code.',
  SIGNUP_FROM_ALERT_FILE_UPLOAD_ERROR_MESSAGE: 'Only files less than 10MB can be registered.',
  SIGNUP_FORM_SUCCESS_NICKNAME: 'Nickname available.',
  SIGNUP_FORM_NICKNAME_VALIDATION_ERROR_MESSAGE: 'Please type up to 10 characters in Korean and English.',
  SIGNUP_FORM_FAIL_NICKNAME: 'Nickname unavailable.',
  SIGNUP_FORM_FAIL_EMAIL: 'Please fill in your email address in the correct format.',
  ID_SIGNUP_ERROR_1: 'This phone number is already registered.',
  ID_SIGNUP_ERROR_2: 'Please request another verification code.',
  HEADER_LOGGED_IN_USER_MESSAGE: "{user}, let's achieve our goals together! ",
  DETAIL_LECTURE_NOT_LOGGED_IN_USER: 'Please login to complete your payment.',
  GENERAL_NOT_LOGGED_IN_USER: 'Please login to complete your process.',
  DETAIL_LECTURE_NOT_SELECT_SCHEDULE: 'Please select lecture schedule.',
  DETAIL_LECTURE_NOT_SELLING: 'This lecture cannot be purchased. Please use our 1:1 inquiry service for assistance.',
  PAYMENT_ERROR_2: 'Payment Error. Error message:',
  PAYMENT_ALREADY_BEEN_PAID: 'This lecture has already been purchased.',
  PAYMENT_NOT_PAID_MESSAGE: 'It is not possible to pay 0 KRW. Please use our 1:1 inquiry service for assistance. ',
  ID_COUPON_ERROR_MEESGAE_1: 'Coupon not valid.',
  ID_COUPON_ERROR_MEESGAE_2: 'Coupon expired. ',
  ID_COUPON_ERROR_MEESGAE_3: 'Coupon not available. Please use our 1:1 inquiry service for assistance. ',
  ID_COUPON_ERROR_MEESGAE_4: 'Coupon already registered.',
  MY_PAGE_PROFILE_MESSAGE_1: 'Your valuable information can only be edited by our own management team!',
  MY_PAGE_PROFILE_MESSAGE_2: 'Please use our 1:1 inquiry service and we will get back to you swiftly. ',
  MY_PAGE_PROFILE_INQUIRY: 'Leave an inquiry',
  MY_PAGE_PROFILE_SIGNOUT: 'Delete account',
  MY_PAGE_SIGNOUT_MESSAGE: '"Are you sure you want to delete your account? We\'ll be sad to see you go."',
  MY_PAGE_SIGNOUT_TERM_1:
    'If you delete your account, you will not be able to access lectures that you are currently taking or that you were planning to take in the future. ',
  MY_PAGE_SIGNOUT_TERM_2:
    'If you delete your account, all your coupons will also be deleted. Even if you re-register, they will not be restored.',
  MY_PAGE_SIGNOUT_TERM_3: 'Reviews will not be separately deleted.',
  MY_PAGE_SIGNOUT_TERM_AGREE: 'I have read and agreed to the account deletion notice above.',
  MY_PAGE_SIGNOUT_FORM: 'My reason for deleting my account with Enterjob Edu…',
  MY_PAGE_SIGNOUT_CONFIRM: 'Confirm deletion',
  MY_PAGE_SIGNOUT_POLICY: 'The deleted account cannot be used to re-register for the next 30 days!',
  MY_PAGE_NONE_EXPIRED_COUPON: 'You have no used/expired coupons',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD1: 'Check spam folder',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD2: '1:1 inquiry service',
  MY_PAGE_PAYMENT_MODAL_REFUND_BOLD: '1:1 inquiry service',
  COMMON_CANCEL: 'Cancel',
  DEVELOP_MESSAGE: 'The Enterjob Edu service is being prepared.',
  USED: 'Used',
  EXPIRED: 'Expired',
  GO_TO_SERVICE_CENTER: 'Go to customer service center',
  ERROR_GENERAL: 'An unknown error has occurred',
  ERROR_NETWORK: 'Please check your network.',
  ID_SIGNUP_ERROR_3: 'This email address is already registered.',
  ID_SIGNUP_ERROR_4: 'Failed to send reply email.',
  ID_SIGNUP_ERROR_5: 'You are already a registered user.',
  ID_SIGNUP_ERROR_6: 'You can re-register only 30 days after the deletion of your account. ',
  SIGNUP_FORM_INTERESTED_COMPANY: 'Company of interest (required)',
  SIGNUP_FORM_INTERESTED_COMPANY_SELECT: 'Please select a company. (multiple selection)',
  SIGNUP_FORM_INTERESTED_COMPANY_ETC: 'Other',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER: 'Other companies (required)',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER_PLACEHOLDER: 'Please enter other companies.',
  SIGNUP_FORM_COUNTRY: 'Nationality (required)',
  MY_PAGE_LECTURES_SINGLE: 'Single lectures ',
  MY_PAGE_LECTURES_PACKAGE: 'Package lectures',
  마감임박: 'Last minute offer',
  '이미 마감된 강의입니다': 'The class is closed.',

  'Album Credit DB': 'Album Credit DB',
  '국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요.':
    '"Album Credit DB Center, where you can find information on {totalCount} albums that were released in Korea!{br}You can check out information on the Staff, who promote KPOP to the world."',
  RELEASE_DATE_DESC: 'Latest Release Order',
  RELEASE_DATE_ASC: 'Previous Release Order',
  '전체 {totalCount}개': 'Total {totalCount}',
  기획사: 'Agency',
  아티스트: 'Artist',
  '{totalCount}개의 앨범 크레딧 보기': 'Check out {totalCount} of our album credits!',
  발매일: 'Release Date',
  '아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?':
    "Aren't you interested in other album information related to other artists?",
  '앨범 크레딧 "이분들의 땀과 노력이 담겼어요!"': 'Album Credit {br}"It contains their sweat and effort!"',
  '다른 앨범 정보도 궁금하지 않으세요?': "Aren't you interested in other album information?",
  MY_PAGE_LECTURES_COLUMN_REVIEW: 'Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE: 'Course Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER:
    'When writing a course review, we will give you a 1,000 won discount coupon within 1 business day!',
  MY_PAGE_LECTURES_WRITE_REVIEW_COMPLETE: 'Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW: 'Write',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE: 'Total Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER:
    'Write your overall opinion on the lecture! (at least 100bytes )\r\nYour review will be a huge asset to those who are considering purchasing our courses and  membership.  ',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER:
    'The question below is to improve EnterJob Edu content. {br}Only Enter Job Edu staff members will be able check this  answer, so please feel free to answer!',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION:
    '<strong>Class Satisfaction</strong> : Were you satisfied with the composition of content, accuracy of information, and overall topic suitablity?',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION:
    "<strong>Class Time Satisfaction</strong> : Were you satisfied with the opening time of the class, the instructor's compliance with the class time, and the overall running time of the lecture?",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION:
    "<strong>Instructor satisfaction</strong> : Please evaluate the instructor's knowledge, experience, and delivery skills!",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER:
    'If there was anything that you were specifically dissatisfied with, we will try our best to improve. ( at least 100bytes )',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION:
    'Is there any other type content or needed functions that Enter Job Edu should consider?',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER:
    'Please write down the type of information you struggled to find while preparing for employment at an entertainment company or what you want to hear from the incumbent!\r\nHere at Enter Job Edu, we will always try our best to solve your problems.',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT: 'Review Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR: 'Please fill in all the categories that are mandatory.',
  REVIEW_WARNING_RATE: 'You must take 30% or higher to write.',
  REVIEW_WARNING_ENDDATE: 'You can write after the end of the course.',
  COMMON_CLOSE: 'Close',
  COMMON_ALWAYS_CLOSE: 'Do not reopen',
  COMMON_1DAY_CLOSE: "Don't open today",
  SEARCH_INPUT_PLACEHOLDER: 'Search for a job, company, etc. you are interested in.',
  SEARCH_RECENT_KEYWORDS_TITLE: 'Recent searches',
  COMMON_DELETE_ALL: 'Delete all',
  SEARCH_RECENT_KEYWORD_EMPTY: 'There are no recent searches.',
  SEARCH_RESULT_HEADER: 'Search results for {keyword}',
  SEARCH_RESULT_EMPTY_KEYWORD: 'No search results for {keyword}.',
  SEARCH_RESULT_EMPTY_DESC:
    'Check the spacing.<br />Check the spelling of the word is correct.<br />Check if you entered Korean in English or English in Korean.<br />Try using a different search term.',
  SEARCH_RECOMMEND_KEYWORDS_TITLE: 'Recommended keywords',
  SEARCH_RECOMMEND_CONTENTS_LECTURES: "Don't miss the popular lectures now!",
  SEARCH_RECOMMEND_CONTENTS_ALBUMS: 'Suggestion! Check out the recently released album credits.',
  MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE: 'Lecture Material',
  MY_PAGE_LECTURES_DOWNLOAD_FILE: 'Download',
  MY_PAGE_PROFILE_UPDATE_SUCCESS: 'The information has been corrected.',
  NO_LECTURES: 'Content is being prepared.',
  LIST: 'List',
  NOTICE_DETAIL_ATTACH: 'Attached File',
  REQUEST_FOR_CLASS: '신청하기 👆🏻',
  LECTURE_SELLING_COUNT: '{count} enrolled',
};

export default en;
